import {
  useContext, useEffect, useMemo, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { modalBack } from 'bv-helpers/location';
import { withErrorBoundary } from 'bv-error-boundary';
import { useLockBodyScroll, useBodyClass } from '../hooks';

import Icon from './icon';
import RenderInBody from './render_in_body';
import ModalWindowContext from '../contexts/modal_window_context';
import ModalRoutingContext from '../contexts/modal_routing_context';
import ConfirmModal from './confirm_modal';
import DotsIcon from './dots_icon';
import ErrorBoundaryFallback from './error_boundary_fallback';

const ModalWindow = ({
  title,
  children,
  renderLeft,
  renderTitle,
  className,
  scrollableRef,
  contentClassName,
  onBack,
  scrollToTop,
  showBack,
  dots,
  onDotsClick,
  onClose,
  confirmClose,
}) => {
  const ref = useRef();
  const scrollableRefFallback = useRef();
  const [confirmCloseCallback, setConfirmCloseCallback] = useState(null);

  useLockBodyScroll(scrollableRef || scrollableRefFallback);
  useBodyClass('has-modal');

  // Scroll to top
  // Will not work when moving from a modal to other
  // Should probably work based in location
  // Very basic for now to achieve same as before in cashier actions
  useEffect(() => {
    if (scrollToTop && (scrollableRef || scrollableRefFallback)?.current) {
      // eslint-disable-next-line no-param-reassign
      (scrollableRef || scrollableRefFallback).current.scrollTop = 0;
    }
  }, [scrollToTop, scrollableRef?.current, scrollableRefFallback?.current]);

  const { isFirstModal = false } = useContext(ModalRoutingContext);

  const closeModal = onClose || modalBack;

  const onCloseClick = () => (confirmClose
    ? setConfirmCloseCallback(() => closeModal)
    : closeModal());

  const onBackClick = () => (confirmClose?.showOnBack
    ? setConfirmCloseCallback(() => onBack)
    : onBack());

  const contextValue = useMemo(
    () => ({ ref, scrollableRef: (scrollableRef || scrollableRefFallback) }),
    [ref, scrollableRef, scrollableRefFallback],
  );

  return (
    <>
      <ModalWindowContext.Provider value={contextValue}>
        <RenderInBody>
          <div
            className={`modal_wrapper${className ? ` ${className}` : ''}`}
            ref={ref}
          >
            <div className="modal_header">
              <div className="modal_header_info_wrapper">
                {(!JSON.parse(isFirstModal) || showBack) && (
                  <span className="back-icon" onClick={onBackClick}>
                    <Icon
                      className="back"
                      id="arrow-left"
                      type="modal-header-back-icon"
                    />
                  </span>
                )}
                {renderLeft && renderLeft()}
              </div>
              <div className="modal_title_wrapper">
                <span className="modal_title">
                  {renderTitle ? renderTitle() : title}
                </span>
                {dots && (
                  <DotsIcon
                    onDotsClick={onDotsClick}
                    type="modal-header-dots-icon"
                  />
                )}
              </div>
              <span className="close">
                <span className="close-icon" onClick={onCloseClick}>
                  <Icon id="close" type="modal-header-close-icon" big />
                </span>
              </span>
            </div>

            <div
              className="modal_contents_wrapper scrollable has-scroll-bars"
              ref={(scrollableRef || scrollableRefFallback)}
            >
              <div
                className={`modal_contents${
                  contentClassName ? ` ${contentClassName}` : ''
                }`}
              >
                {children}
              </div>
            </div>
          </div>
        </RenderInBody>
      </ModalWindowContext.Provider>
      {confirmCloseCallback && (
        <ConfirmModal
          title={confirmClose.title}
          btnNoInversed={confirmClose.btnNoInversed}
          handleNoBtnClick={() => setConfirmCloseCallback(null)}
          handleYesBtnClick={confirmCloseCallback}
        />
      )}
    </>
  );
};

ModalWindow.propTypes = {
  title: PropTypes.string,
  children: PropTypes.instanceOf(Object).isRequired,
  renderLeft: PropTypes.func,
  renderTitle: PropTypes.func,
  className: PropTypes.string,
  scrollableRef: PropTypes.instanceOf(Object),
  contentClassName: PropTypes.string,
  onBack: PropTypes.func,
  scrollToTop: PropTypes.bool,
  showBack: PropTypes.bool,
  dots: PropTypes.bool,
  onDotsClick: PropTypes.func,
  onClose: PropTypes.func,
  confirmClose: PropTypes.instanceOf(Object),
};

ModalWindow.defaultProps = {
  title: '',
  renderLeft: null,
  renderTitle: null,
  className: '',
  scrollableRef: undefined,
  contentClassName: '',
  onBack: () => {
    window.history.back();
  },
  scrollToTop: false,
  showBack: false,
  dots: false,
  onDotsClick: undefined,
  onClose: undefined,
  confirmClose: undefined,
};

export default withErrorBoundary(ModalWindow, {
  FallbackComponent: ErrorBoundaryFallback,
});

// showBack prop should be used very rarely, only when modals are rendered not
// as part of the normal modal routing (offers own router)
// We will probably need a prop in order to force it not to show, when a modal is not the first
// But we want the user not to go back
// Once we have everything properly routed and the prop to not go back
// probably showBack will not be needed
