import PropTypes from 'prop-types';
import { withErrorBoundary } from 'bv-error-boundary';

import { showTopLevelNavigation } from 'global-config';
import { useSeoEnabled } from 'Seo/helpers';
import TabsMenu from './tabs_menu';
import ErrorBoundaryFallback from './error_boundary_fallback';

import withLazyLoad from '../../utils/lazy_load/with_lazy_load';

const LazySeo = withLazyLoad()('seo');

const ContentLayout = ({ showTabs, children }) => {
  const seoEnabled = useSeoEnabled();

  return (
    <div className="react-contents">
      {showTabs && showTopLevelNavigation && <TabsMenu />}
      {children}
      {seoEnabled && <LazySeo />}
    </div>
  );
};

ContentLayout.propTypes = {
  showTabs: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

ContentLayout.defaultProps = {
  showTabs: false,
};

export default withErrorBoundary(ContentLayout, {
  FallbackComponent: ErrorBoundaryFallback,
});
