import { useSelector } from 'react-redux';
import { composeRestrictions } from 'VerificationZone/selectors/restrictions';
import { useMemo } from 'react';

const RestrictionIndex = {
  DEPOSIT: 1,
  WITHDRAWAL: 0,
};

const useRestrictedLink = (restrictionIndex, path) => {
  const restrictions = useSelector(composeRestrictions);

  return useMemo(() => {
    if (!restrictions.length) return '';

    if (!restrictions[restrictionIndex].canPerformAction) {
      return '/account/verification_zone';
    }
    return path;
  }, [restrictions, restrictionIndex, path]);
};

const useDepositLink = () => useRestrictedLink(RestrictionIndex.DEPOSIT, '/bv_cashier/deposit');

const useQuickDepositLink = (value) => useRestrictedLink(
  RestrictionIndex.DEPOSIT, value ? `/quick_deposit/${value}` : '/quick_deposit',
);

const useWithdrawalLink = () => useRestrictedLink(RestrictionIndex.WITHDRAWAL, '/bv_cashier/withdraw');

export { useDepositLink, useWithdrawalLink, useQuickDepositLink };
