import { isDesktop } from 'bv';

export const calculateScrollPosition = (elementPos, navRef, fixedNav) => {
  const scrollAmount = isDesktop() ? 256 : 235;
  const navHeight = navRef.current.clientHeight;
  const newPosition = elementPos - (isDesktop() ? 56 : 84 + navHeight);

  if (!isDesktop()) {
    if (!fixedNav && newPosition > scrollAmount) return newPosition - navHeight - 6;
    if (fixedNav && newPosition < scrollAmount) return scrollAmount - 1;
    if (newPosition !== scrollAmount - 1) return newPosition + 23;
  }
  return newPosition;
};
