import {
  forwardRef, useState, useEffect, useRef, useCallback,
} from 'react';

import PropTypes from 'prop-types';
import { throttle, times, flatten } from 'underscore';
import { isDesktop } from 'bv';

import { useWindowSize } from '../../hooks';
import Carousel from './carousel';

const CarouselContainer = forwardRef((props, ref) => {
  // state variables
  const [displayArrows, setDisplayArrows] = useState(props.displayFixedArrows);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const [isInfinite, setIsInfinite] = useState(false);
  const [originalListWidth, setOriginalListWidth] = useState();

  // refs
  const carouselWrapEl = useRef(null);
  const carouselElRef = useRef(null);
  const carouselEl = ref || carouselElRef;

  // props
  const {
    scrollStep,
    activeRef,
    scrollTo,
    infinite,
    children,
    ...rest
  } = props;

  // callbacks
  const scroll = useCallback(
    (multiplier) => () => {
      const scrollAmount = carouselEl.current.scrollLeft + multiplier * scrollStep;

      if (carouselEl.current.scrollTo) {
        carouselEl.current.scrollTo({ left: scrollAmount, behavior: 'smooth' });
      } else {
        carouselEl.current.scrollLeft = scrollAmount;
      }
    },
    [carouselEl, scrollStep],
  );

  const setArrowsStatus = useCallback(
    () => {
      const element = carouselEl.current;

      setCanScrollLeft(element.scrollLeft > 0);

      if (isInfinite) return;
      setCanScrollRight(element.scrollLeft + element.clientWidth < element.scrollWidth);
    },
    [carouselEl, setCanScrollLeft, setCanScrollRight, isInfinite],
  );

  // The idea of this method is to move the scroll to a position in which the same content
  // Is shown, giving the feeling that the list never ends, but actually we are positioning
  // The scroll to the right or to the left when we scroll to the left/right
  const adjustInfiniteScroll = useCallback(
    () => {
      const element = carouselEl.current;

      if (element.scrollLeft > originalListWidth) {
        element.scrollLeft -= originalListWidth;
      }
    },
    [carouselEl, originalListWidth],
  );

  // Check if infinite scrolling should be applied or not
  const initInfinite = useCallback(
    () => {
      const element = carouselEl.current;
      setIsInfinite(true);

      // Padding right is not considered because it's a flex without after content
      const { paddingLeft } = getComputedStyle(element);
      const padding = parseInt(paddingLeft, 10);

      setOriginalListWidth(element.scrollWidth - padding);
    },
    [],
  );

  // On scroll we want to:
  // set the arrow status (check if end is reached) (infinite: false)
  // adjust the scroll to simulate the endless list (infinite: true)
  const onScroll = useCallback(
    throttle(() => {
      if (infinite && !isInfinite) initInfinite();
      if (isInfinite) adjustInfiniteScroll();
      if (displayArrows) setArrowsStatus();
    }, 100),
    [infinite, displayArrows, infinite, setArrowsStatus, adjustInfiniteScroll],
  );

  const { innerWidth } = useWindowSize();

  // effects
  useEffect(() => {
    if (isDesktop()) {
      if (carouselWrapEl.current.clientWidth < carouselEl.current.scrollWidth) {
        setDisplayArrows(true);
        setArrowsStatus();
      } else {
        setDisplayArrows(false);
      }
    }
  }, [children, props.dangerouslySetInnerHTML, innerWidth]);

  const getScrollAmount = (slider, item) => (
    item.offsetLeft
    - slider.offsetLeft
    - (slider.clientWidth / 2)
    + (item.offsetWidth / 2)
  );

  useEffect(() => {
    if (activeRef && activeRef.current) {
      const element = carouselEl.current;

      element.scrollLeft = getScrollAmount(element, activeRef.current);
    }
  }, [displayArrows]);

  // When 'scrollTo' item passed as prop scroll it to center if not fully visible
  useEffect(() => {
    if (scrollTo) {
      const element = carouselEl.current;

      if ((scrollTo.offsetLeft - element.offsetLeft < element.scrollLeft)
        || (scrollTo.offsetLeft + scrollTo.offsetWidth
        > element.scrollLeft + element.clientWidth + element.offsetLeft)) {
        element.scrollLeft = getScrollAmount(element, scrollTo);
      }
    }
  }, [scrollTo, displayArrows]);

  // render
  return (
    <Carousel
      canScrollLeft={canScrollLeft}
      canScrollRight={canScrollRight || infinite}
      carouselWrapElRef={carouselWrapEl}
      carouselElRef={carouselEl}
      displayArrows={displayArrows}
      onScroll={onScroll}
      scroll={scroll}
      {...rest}
    >
      {isInfinite ? flatten(times(2, () => children)) : children}
    </Carousel>
  );
});

CarouselContainer.propTypes = {
  scrollStep: PropTypes.number.isRequired,
  activeRef: PropTypes.node,
  scrollTo: PropTypes.instanceOf(Object),
  children: PropTypes.node.isRequired,
  dangerouslySetInnerHTML: PropTypes.instanceOf(Object),
  infinite: PropTypes.bool,
  displayFixedArrows: PropTypes.bool,
};

CarouselContainer.defaultProps = {
  activeRef: null,
  scrollTo: null,
  dangerouslySetInnerHTML: null,
  infinite: false,
  displayFixedArrows: false,
};

CarouselContainer.displayName = 'CarouselContainer';

export default CarouselContainer;
